<template>
  <div class="membership" id="membership">
    <div class="ui grid container faq">
      <div class="intro-content">
        <p class="path">Frequently Asked Questions > Membership</p>
        <h3>Membership</h3>
        <h4 id="mbs1">What are the different types of Member Accounts of TayoCash?</h4>
        <div class="paragraph">
          <p>TayoCash has <span class="vtxt-align">3 types of Member Accounts</span>, subject to different verification levels and wallet limits:</p>
          <ol>
            <li>Basic Member Account</li>
            <li>Semi-Verified Member Account</li>
            <li>Fully Verified Member Account</li>
          </ol>
        </div>

        <h4 id="mbs2">What are the features and services available for each type of Member Account?</h4>
        <div class="paragraph second">
          <ol>
            <li>
              <span class="vtxt-align">Basic:</span> Members can Cash In, Buy Load, and Pay Bills.
            </li>
            <li>
              <span class="vtxt-align">Semi-verified:</span> Members can Cash In, Cash Out, Buy Load, Pay Bills, Fund Transfer, PayQR, and access other features of the TayoCash App within the applicable wallet limit.
            </li>
            <li>
              <span class="vtxt-align">Fully Verified:</span> Members can Cash In, Cash Out, Buy Load, Pay Bills, Fund Transfer, Pay QR, and access other features of the TayoCash App within the applicable wallet limit. Members can also upgrade to become a TayoCash Partner or Merchant.
            </li>
          </ol>
        </div>

        <h4 id="mbs3">What are the wallet limits and requirements for each type of Member Account?</h4>

        <div class="ui four column grid table-style">
          <div class="row">
            <div class="column only first">
              <div class="content first">
                <div class="meta wallet">
                  <span>Wallet Limit</span>
                </div>
              </div>
            </div>
            <div class="column second">
              <div class="content">
                <div class="header">BASIC</div>
                <div class="meta">
                  <span>Daily and Monthly</span>
                  <span>(Incoming): P50,000</span>
                </div>
              </div>
            </div>
            <div class="column third">
              <div class="content">
                <div class="header">SEMI-VERIFIED</div>
                <div class="meta">
                  <span>Daily (Incoming & Outgoing): P50,000</span> <br>
                  <span>Monthly and Yearly (Incoming &
                  Outoing): P100,000</span>
                </div>
              </div>
            </div>
            <div class="column">
              <div class="content">
                <div class="header">FULLY VERIFIED</div>
                <div class="meta">
                  <span>  Daily and Monthly (Incoming & Outgoing): P100,000</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="column first">
              <div class="content first">
                <div class="meta">
                  <span>Features Available</span>
                </div>
              </div>
            </div>
            <div class="column second">
              <div class="content">
                <div class="meta">
                  <span>Cash In, Pay Bills, Buy Load</span>
                </div>
              </div>
            </div>
            <div class="column third">
              <div class="content">
                <div class="meta">
                  <span>
                    Cash In, Cash Out, Pay Bills, Buy Load,
                    Fund Transfer, Pay QR,
                    Remittance
                  </span>
                </div>
              </div>
            </div>
            <div class="column">
              <div class="content">
                <div class="meta">
                  <span>
                    Cash In, Cash Out, Pay Bills, Buy Load,
                    Fund Transfer, Pay QR,
                    Remittance, Be a Partner or Merchant
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="column first">
              <div class="content first">
                <div class="meta">
                  <span>
                    Registration Requirements
                  </span>
                </div>
              </div>
            </div>
            <div class="column second">
              <div class="content">
                <div class="meta">
                  <span>a. Full Name</span>
                  <span>b. Mobile Number</span>
                </div>
              </div>
            </div>
            <div class="column third">
              <div class="content">
                <div class="meta data">
                  <span>a. Full Name</span>
                  <span>b. Civil Status</span>
                  <span>c. Nationality</span>
                  <span>d. Email Signature or
                    Email Address</span>
                  <span>e. Complete Address</span>
                  <span>f. Birthdate and Birth Place</span>
                  <span>g. Source of Funds</span>
                  <span>h. Employer’s Name</span>
                  <span>i. Business Address</span>
                  <span>j. Nature of Industry</span>
                  <span>k. Video selfie</span>
                  <span>l. Other Supporting Documents
                   if necessary</span>
                </div>
              </div>
            </div>
            <div class="column">
              <div class="content">
                <div class="meta data">
                  <span>a. All requirements for
                      Semi-Verified Members</span>
                  <span>b. Government-issued ID</span>
                  <span>c. Other Supporting Documents if necessary</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mobile-content">
          <div class="ui grid three column row">
            <div class="column">
              <div class="content">
                <div class="title">BASIC</div>
                <p>Daily and Monthly <br>
                  (Incoming): P50,000
                </p>
                <p>Cash In, Pay Bills, Buy Load</p>
                <p class="data">a. Full Name <br>
                  b. Mobile Number
                </p>
              </div>
            </div>
            <div class="column">
              <div class="content">
                <div class="title">SEMI-VERIFIED</div>
                <p>Daily (Incoming & Outgoing): P50,000 <br>
                  Monthly and Yearly (Incoming &
                  Outoing): P100,000
                </p>
                <p>Cash In, Cash Out, Pay Bills, Buy Load,
                  Fund Transfer, Pay QR,
                  Remittance
                </p>
                <p>
                  <ol>
                    <li>Full Name</li>
                    <li>Civil Status</li>
                    <li>Nationality</li>
                    <li>Email Signature or
                        Email Address</li>
                    <li>Complete Address</li>
                    <li>Birthdate and Birth Place</li>
                    <li>Source of Funds</li>
                    <li>Employer’s Name</li>
                    <li>Business Address</li>
                    <li>Nature of Industry</li>
                    <li>Video selfie</li>
                    <li> Other Supporting Documents
                       if necessary </li>
                  </ol>
                </p>
              </div>
            </div>
            <div class="column">
              <div class="content">
                <div class="title">FULLY VERIFIED</div>
                <p>Daily and Monthly (Incoming <br>
                  & Outgoing): P100,000
                </p>
                <p>Cash In, Cash Out, Pay Bills, Buy Load,
                  Fund Transfer, Pay QR,
                  Remittance, Become a Partner
                  or Merchant
                </p>
                <p>
                  <ol>
                    <li>All requirements for Semi-Verified Members </li>
                    <li>Government-issued ID</li>
                    <li>Other Supporting Documents if necessary</li>
                  </ol>
                </p>
              </div>
            </div>
          </div>
        </div>

        <p>All types of Member Accounts are subject to TayoCash's <a class="link" href="/termsandconditions">Terms and Conditions</a>.</p>
        <p>In order to unlock all of TayoCash’s features and services or to enroll to become a partner, you need to undergo full verification.</p>

        <div class="buttons">
          <a href="/faq/gettingstarted" class="yellow">BACK</a>
          <a href="/faq/verification" class="white">NEXT</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'membership'
}
</script>

<style scoped lang='scss'></style>
